<template id="rezervari">
    <div class="content">
    
        <el-card > 

        <p class="title" style="text-align: center;">
            <H3>Politica de confidențialitate</H3>
        </p>
    </br>

        <ol type="I">
            <li>
                <strong>Identitatea și datele de identificare ale operatorului</strong>
                
                <p>Potrivit prevederilor Regulamentului (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (denumit în continuare &#8220;GDPR&#8221;), S.C. WEBTRADE MARKETING S.R.L., societate inregistrata la Registrul Comertului cu numarul J23/5474/2023, cod unic de inregistrare RO28555370, cu sediul social in bld. Pipera 1E, Voluntari, Ilfov (pe scurt „[WEBTRADE MARKETING]” sau „noi”) este operator de date cu caracter personal.&nbsp;</p>

                <p>Protecția datelor Dumneavoastră cu caracter personal este foarte importantă pentru noi. De aceea, pentru ca datele Dumneavoastră să fie prelucrate în siguranță și în conformitate cu dispozițiile legale aplicabile, noi am depus toate eforturile în vederea implementării măsurilor adecvate și rezonabile, tehnice și organizatorice necesare pentru a proteja datele Dumneavoastră cu caracter personal.</p>

                <p><strong>Vă recomandăm să citiți cu atenție prezenta Politică de Confidențialitate, pentru a fi informați cu privire la modalitatea în care noi vom prelucra datele Dumneavoastră cu caracter personal, precum și care sunt drepturile Dumneavoastră în temeiul legislației privind protecția datelor și modalitatea în care vi le puteți exercita.</strong></p>

                <p>În plus, ca urmare a parcurgerii acestei Politici de confidențialitate veți fi informați cu privire la categoriile de date cu caracter personal pe care le vom prelucra, scopurile în care vă vom prelua datele cu caracter personal, care este temeiul prelucrării și, nu în ultimul rând, perioada în care vă vom stoca respectivele date cu caracter personal.</p>

                <p>Dacă nu sunteți de acord cu cele descrise în prezenta Politică de Confidențialitate, vă rugăm să nu utilizați serviciile noastre.</p>

            </li>
            <li>
                <strong>Definiții</strong></br>
        
                <p><em>„GDPR”</em> sau <em>„Regulamentul”</em> înseamnă Regulamentul nr. 679 din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal şi privind libera circulație a acestor date şi de abrogare a Directivei 95/46/CE (Regulamentul general privind protecția datelor).</p>

                <p><em>„Operatorul”</em> înseamnă&nbsp; S.C. WEBTRADE MARKETING S.R.L., societate inregistrata la Registrul Comertului cu numarul J23/5474/2023, cod unic de inregistrare RO28555370, cu sediul social in bld. Pipera 1E, Voluntari, Ilfov.</p>

                <p><em>„Persoana vizată”</em> înseamnă orice persoană fizică identificată sau identificabilă ale cărei date cu caracter personal sunt prelucrate de către Operator. Spre exemplu, sunt persoane vizate clienții, potențialii clienți sau vizitatorii website-ului.</p>

                <p><em>„Prelucrare”</em> înseamnă orice operațiune sau set de operațiuni efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi colectarea, înregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la dispoziție în orice alt mod, alinierea sau combinarea, restricționarea, ștergerea sau distrugerea.</p>

                <p>„<em>Consimțământ</em>&#8221; al persoanei vizate înseamnă orice manifestare de voință liberă, specifică, informată şi lipsită de ambiguitate a persoanei vizate prin care aceasta acceptă, printr-o declarație sau printr-o acțiune fără echivoc, ca datele cu caracter personal care o privesc să fie prelucrate.</p>

                <p>„<em>Date cu caracter personal</em>&#8221; înseamnă orice informații privind o persoană fizică identificată sau identificabilă („<em>persoana vizată</em>&#8220;); o persoană fizică identificabilă este o persoană care poate fi&nbsp; identificată, direct sau indirect, în special prin referire la un element de identificare, cum ar fi un nume, un număr de identificare, date de localizare, un identificator online, sau la unul sau mai multe elemente specifice, proprii identității sale fizice, fiziologice, genetice, psihice, economice, culturale sau sociale.</p>

                <p>Ceilalți termeni utilizați în cadrul prezentei Politici de confidențialitate au înțelesul conferit de GDPR și de celelalte prevederi legale aplicabile.</p>

            </li>
            <li><strong>Modificarea prezentei Politici de confidențialitate</strong></br>

                <p>Ne rezervăm dreptul de a actualiza această Politică ori de câte ori este nevoie, spre exemplu, atunci când au loc modificări legislative în materia prelucrării datelor cu caracter personal, publicând o versiune nouă pe website-ul nostru. Toate actualizările și modificările prezentei Politici sunt valabile de la momentul la care acestea au fost afișate pe website. Data ultimei revizuiri a Politicii noastre de confidențialitate este menționată în partea de sus a paginii.</p>

                <p>Trebuie să verificați ocazional această pagină pentru a vă asigura că înțelegeți orice modificare adusă acestei Politici. Vă putem anunța despre modificările semnificative aduse acestei Politici prin e-mail.</p>


            </li>
            <li>
                <strong>Categoriile de date cu caracter personale prelucrate</strong></br>
            

                <p>În vederea îndeplinirii scopurilor de prelucrare definite mai jos, prelucrăm mai mult categorii de date cu caracter personal, după cum urmează:</p>

                <ul>
                    <li>Date cu caracter personal furnizate direct de către Dumneavoastră ca urmare a creării unui cont pe website-ul nostru: date de identificare și de contact (nume, prenume, username folosit la momentul creării contului, sex, data nașterii, vârstă, adresă de e-mail, număr de telefon);</li>
                    <li>Date cu caracter personal furnizate direct de către Dumneavoastră ca urmare lansării unei comenzi sau a efectuării unei rezervări online: date de identificare și de contact (în plus, adresă poștală de domiciliu/ reședință sau de livrare, date bancare, necesare pentru plata cu cardul, precum număr de cont (IBAN), număr de card, numele și prenumele deținătorului, codul CVV/CVC), respectiv în cazul în care v-ați abonat la Newsletter (adresă de e-mail);</li>
                    <li>Date cu caracter personal furnizate indirect de către Dumneavoastră ca urmare a utilizării website-ului nostru și a efectuării unei comenzi sau rezervări online (adresă IP utilizată de Dumneavoastră pentru a vă conecta la internet, utilizând computerul sau telefonul mobil, browser, locație);</li>
                    <li>Date cu caracter personal furnizate în mod voluntar de către Dumneavoastră (informații privind comenzile sau rezervările efectuate de Dumneavoastră și feedback-ul/comentariile Dumneavoastră) sau pe care le deținem deja în cadrul unui raport contractual existent;</li>
                    <li>Date cu caracter personal obținute de la terți (instanță de judecată, autorități publice sau terți externi); vom prelucra datele cu caracter personal primite de la terți externi numai dacă Dumneavoastră v-ați dat consimțământul în sensul transmiterii respectivelor date cu caracter personal către noi;</li>
                
                    <li>Date pe care le obținem ca urmare a prelucrării datelor cu caracter personal de mai sus (număr de comandă, cod de reducere, valoare reducere, cost transport, valoarea totală comandă, spre exemplu).</li>
                </ul>

            </li>
        </br>
            <li>
                <strong>Scopurile și temeiurile legale ale prelucrării</strong></br>

                <p>Datele Dumneavoastră cu caracter personal sunt prelucrate în principiu în următoarele scopuri:</p>

                <ul>
                    <li>
            
                        <strong>Pentru încheierea și executarea unui contract</strong> (art. 6 alin. (1) lit. b) din GDPR):</br>
                        <ul>
                            <li>Crearea și administrarea contului în cadrul website-ului nostru;</li>
                            <li>Onorarea comenzilor (preluare, confirmare, expediere și facturare) și a rezervărilor online;</li>
                            <li>Soluționarea oricăror probleme sau reclamații cu privire la o comandă online, respectiv cu privire la bunurile și/sau serviciile achiziționate, inclusiv anularea unei comenzi sau rezervări online;</li>
                            <li>Returnarea sau înlocuirea produselor, în conformitate cu dispozițiile legale în vigoare;</li>
                            <li>Rambursarea sumelor aferente produselor și/sau serviciilor, în conformitate cu dispozițiile legale în vigoare;</li>
                            <li>Asigurarea serviciilor de suport tehnic, oferirea de răspunsuri la întrebările Dumneavoastră adresate la datele de contact ale [WEBTRADE MARKETING S.R.L.] cu privire la produsele și serviciile disponibile pe website, la comenzile Dumneavoastră sau orice alte solicitări în legătură cu produsele și/sau serviciile noastre.</li>
                        </ul>

                    </li>
                    <li>
                        <strong>În vederea îndeplinirii obligațiilor legale care ne revin</strong>, precum obligații fiscale și financiar-contabile (art. 6 alin. (1) lit. c) din GDPR).</br>

                        <ol>
                            <li><strong>În interesul nostru legitim sau al partenerilor noștri</strong>, având întotdeauna grijă ca interesele, drepturile și libertățile fundamentale ale clienților noștri să prevaleze. Spre exemplu, vom prelucra datele Dumneavoastră cu caracter personal atât pentru a preveni și combate actele de fraudă și posibilele infracțiuni, cât și în scopuri statistice și de analiză a serviciilor. Astfel, vom analiza comportamentul și tendințele clienților noștri, pentru a înțelege cum utilizează website-ul nostru, dar și pentru a gestiona și îmbunătăți constant caracteristicile serviciilor noastre (art. 6 alin. (1) lit. f) din GDPR).</li>
                            <li><strong>În baza consimțământului Dumneavoastră expres </strong>(art. 6 alin. (1) lit. a) din GDPR), în cazul în care doriți să vă partajăm datele cu caracter personal către terți sau în scopul comunicărilor comerciale (de marketing, publicitate, desfășurare campanii promoționale, transmiterea de newslettere, etc.). Dacă nu mai doriți să primiți informații și/sau comunicări comerciale (precum promoții sau oferte), puteți oricând din mesajul e-mail să vă dezabonați, printr-un click pe „Dezabonare”, iar noi vom opri imediat transmiterea acestor categorii de mesaje.</li>
                        </ol>   
                    </li>
                </ul>    
            </li>
            <li>
                <strong>Destinatarii sau categoriile de destinatari ai datelor cu caracter personal</strong>

                <p>În scopul executării contractului încheiat între noi și Dumneavoastră, vom furniza, atunci când va fi nevoie, anumite date cu caracter personal ale Dumneavoastră următoarelor categorii de destinatari:</p>
                <ol>
                    <li>Furnizorilor de servicii de curierat, pentru ca produsele comandate de Dumneavoastră să fie livrate la adresa indicată de Dumneavoastră. De asemenea, curierul va avea acces la numărul Dumneavoastră de telefon pentru a vă putea contacta în cazul în care întâmpină dificultăți în a găsi adresa de destinație sau nu sunteți la adresa de destinație;</li>
                    <li>Furnizorilor de servicii de plată/bancare;</li>
                    <li>Furnizori de servicii de marketing și de studii și cercetări de piață;</li>
                    <li>Furnizori de servicii de telecomunicații;</li>
                    <li>Asigurători (companii de asigurare), dacă este cazul.</li>
                </ol>

                <br>
                <p>De asemenea, ori de câte ori autoritățile ne vor solicita datele Dumneavoastră cu caracter personal, indicând temeiul legal al acestei solicitări, [WEBTRADE MARKETING S.R.L.] vom furniza datele Dumneavoastră cu caracter personal. Menționăm că vom transmite datele personale absolut necesare specificate, respectând principiul minimizării datelor.</p>

                <p>Destinatarilor le este interzis să utilizeze datele cu caracter personal la care au acces pentru scopuri proprii sau comerciale sau în vederea transmiterii către terțe persoane.&nbsp;</p>

                <p>Datele Dumneavoastră vor fi dezvăluite terților numai atunci <em>(1)</em> când sunt necesare în vederea furnizării serviciilor solicitate de Dumneavoastră (comanda/rezervare online), în situațiile în care colaborăm cu terți (furnizori de servicii de curierat), <em>(2)</em> când există consimțământul Dumneavoastră expres și neechivoc, <em>(3)</em> când au fost solicitate de către autoritățile publice competente, precum și <em>(4) </em>pentru îndeplinirea unei obligații legale.&nbsp;</p>
                
            </li> 
            <li><strong>Perioada stocării</strong>

                <p>Vom stoca datele Dumneavoastră cu caracter personal pe perioada necesară îndeplinirii scopurilor indicate în Secțiunea V de mai sus.</p>

                <p>Așadar, perioada de stocare a datelor Dumneavoastră cu caracter personal variază în funcție de scopul prelucrării, și anume:</p>

                <ol>
                    <li>În cazul în care ați efectuat o comandă sau o rezervare online, noi vom prelucra datele Dumneavoastră cu caracter personal pe perioada necesară onorării comenzii lansate sau până la ridicarea personală din magazin, precum și după încheierea și executarea completă a contractului, până la expirarea termenelor de garanție corespunzătoare produselor achiziționate, termenelor de prescripție și a celor impuse de legile financiar-contabile. De asemenea, vom folosi datele Dumneavoastră până la finalizarea soluționării eventualelor reclamații sau litigii legate de comanda lansată sau rezervarea efectuată sau de calitatea intrinsecă sau extrinsecă a produselor, dacă aceste date sunt necesare pentru apărare drepturilor noastre.</li>
                    <li>În cazul în care v-ați abonat la Newsletter, vom prelucra datele cu caracter personal până la momentul dezabonării Dumneavoastră.</li>
                </ol>

                <p>Odată ce datele nu vor mai fi necesare pentru îndeplinirea acestor scopuri, le vom <em>(i)</em> distruge sau le vom <em>(ii)</em> anonimiza și păstra exclusiv pentru scopuri statistice, de cercetare istorică sau științifică.&nbsp;</p>

                <p>Totodată, puteți să solicitați oricând, iar noi ne vom conforma imediat solicitării Dumneavoastră, ca anumite sau toate datele cu caracter personal stocate de către noi să fie șterse. De asemenea, puteți solicita oricând închiderea contului Dumneavoastră. Ca urmare a unor astfel de solicitări, vă informăm că vom păstra numai acele date cu caracter personal pe care legislația în vigoare sau interesele noastre legitime ne obligă să le păstrăm.</p>

            </li>
            <li>
                <strong>Drepturile persoanei vizate</strong></br>

                <p>Drepturile Dumneavoastră conform Regulamentului sunt următoarele:</p>

                    <ol>
                        <li><strong>Dreptul de acces asupra datelor.</strong> Așadar, aveți dreptul de a fi informat cu privire la prelucrarea datelor cu caracter personal, precum și dreptul de a solicita o copie a datelor cu caracter personal care fac obiectul prelucrării, precum și informații despre modul cum sunt gestionate datele Dumneavoastră, fără a aduce atingere drepturilor și libertăților altora;</li>
                        <li><strong>Dreptul de a obține rectificarea fără întârziere a datelor inexacte sau incomplete</strong>, ca urmare a unei cereri din partea Dumneavoastră;</li>
                        <li>
                            <strong>Dreptul la ștergerea datelor („dreptul de a fi uitat”). </strong>Aveți dreptul de a obține ștergerea datelor cu caracter personal care vă privesc, fără întârzieri nejustificate, atunci când:

                                <ul>
                                    <li>Datele cu caracter personal nu mai sunt necesare pentru îndeplinirea scopurilor pentru care au fost colectate sau prelucrate;</li>
                                    <li>V-ați retras consimțământul pe baza căruia are loc prelucrarea și nu există niciun alt temei juridic pentru prelucrare;</li>
                                    <li>V-ați opus prelucrării și nu există motive legitime care să prevaleze în ceea ce privește prelucrarea. În cazul prelucrării în scopul de marketing vă puteți opune prelucrării în orice moment, fără a fi necesara o justificare în acest sens;</li>
                                    <li>Datele Dumneavoastră cu caracter personal au fost prelucrate în mod ilegal;</li>
                                    <li>Datele Dumneavoastră cu caracter personal trebuie șterse pentru respectarea unei obligații legale care revine operatorului;
                
                                    </li>
                                </ul>
                            
                                <p>Cu toate acestea, dreptul la ștergerea datelor cu caracter personal nu este aplicabil când prelucrarea este necesară:

                                    <ul>
                                        <li>pentru exercitarea dreptului la libera exprimare și la informare;</li>
                                        <li>pentru respectarea unei obligații legale a operatorului;</li>
                                        <li>în scopuri de arhivare în interes public, în scopuri de cercetare științifică sau istorică ori în scopuri statistice, în măsura în care dreptul la ștergere este susceptibil să facă imposibilă sau să afecteze în mod grav realizarea obiectivelor prelucrării respective;</li>
                                        <li>pentru constatarea, exercitarea sau apărarea unui drept in instanță.</li>
                                    </ul>
                                </p>
                        
                        </li>
                        <li><strong>Dreptul la restricționarea prelucrării. </strong>Acest drept poate fi exercitat când:</li>
                

                    <ul >
                        <li>contestați exactitatea datelor;</li>
                        <li>prelucrarea este ilegală, dar nu doriți să fie șterse datele;</li>
                        <li>datele nu mai sunt necesare în scopul inițial, dar nu pot fi șterse încă din motive juridice (existând o obligație legală de păstrare a lor pentru o anumită perioadă);</li>
                        <li>se așteaptă o decizie cu privire la obiecția Dumneavoastră.</li>
                    </ul>

                    <p>În cazul în care prelucrarea a fost restricționată, datele Dumneavoastră pot, cu excepția stocării, să fie prelucrate numai cu consimțământul Dumneavoastră sau pentru constatarea, exercitarea sau apărarea unui drept în instanță, precum și pentru protecția drepturilor unei alte persoane fizice sau juridice sau din motive de interes public.</p>

                
                    <li><strong>Dreptul la portabilitatea datelor</strong>, care presupunedreptul de a primi datele cu caracter personal într-o modalitate structurată, folosită în mod obișnuit și într-un format ușor de citit, precum și dreptul ca aceste date să fie transmise către alt operator de date, în măsura în care sunt îndeplinite condițiile prevăzute de lege;</li>
                    <li><strong>Dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată, inclusiv crearea de profiluri cu efecte juridice sau cu efecte semnificative similare asupra Dumneavoastră</strong>;</li>
                    <li><strong>Dreptul de opoziție</strong>, potrivit căruia vă puteți opune activităților de prelucrare, în condițiile legii. Atunci când prelucrarea datelor cu caracter personal are drept scop marketingul direct, aveți dreptul de a vă opune în orice moment prelucrării în acest scop a datelor cu caracter personal care vă privesc, fără a fi necesara o justificare în acest sens, inclusiv creării de profiluri, în măsura în care este legată de marketingul direct respectiv;</li>
                    <li><strong>Dreptul de a vă adresa justiției pentru apărarea drepturilor și intereselor Dumneavoastră, </strong>în cazul în care considerați că gestionarea datelor s-a realizat fără respectarea legislației în vigoare<strong>;</strong></li>
                    <li>
                        <strong>Dreptul de a face plângere în fața Autorității Naționale de Supraveghere a Prelucrării Datelor cu Caracter Personal</strong>, cu sediu în Bd. G-ral. Gheorghe Magheru, nr. 28-30, sector 1, București, tel. +40.31.805.9211 sau +40.318.059.212, fax: +40.31.805.9602, website: www.dataprotection.ro, email: <a href="mailto:anspdcp@dataprotection.ro">anspdcp@dataprotection.ro</a>.&nbsp;
                        <p>Fără a afecta dreptul Dumneavoastră de a contacta în orice moment Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal, vă rugăm să ne contactați în prealabil. Vă asigurăm că vom depune toate eforturile necesare pentru a rezolva orice divergență pe cale amiabilă.&nbsp; &nbsp;</p>
                    </li>
                </ol>

                

            </li>
            <li>
                <strong>Prevederi speciale privind prelucrarea datelor cu caracter personal ale persoanelor cu vârsta mai mică de 16 ani</strong></br>

                <p>Acest website nu se adresează persoanelor cu vârsta mai mică de 16 ani. Prin urmare, [WEBTRADE MARKETING S.R.L.] nu prelucrează datele personale ale persoanelor cu vârsta mai mică de 16 ani având la bază consimțământului acestora.&nbsp;</p>

                <p>În situația în care vom descoperi că am prelucrat în mod accidental date personale ce aparțin unei persoane cu vârsta mai mică de 16 ani, în scopuri ce necesită consimțământul, și nu există un acord din partea titularului răspunderii părintești, vom șterge acele date în cel mai scurt timp posibil.&nbsp;</p>

                <p>Totuși, vom putea colecta date cu caracter personal de la persoane cu vârsta mai mică de 16 ani, în cazuri speciale, atunci când există consimțământul expres acordat în acest sens de către părinți sau reprezentanți legali.&nbsp;</p>

            </li>
            <li>
                <strong>Datele de contact ale responsabilului cu protecția datelor</strong></br>

                <p>Pentru orice informații suplimentare în legătură cu protecția datelor cu caracter personal, respectiv în măsură în care apreciați că datele Dumneavoastră cu caracter personal nu sunt prelucrate în conformitate cu legislația în vigoare și cu prezenta Politica de confidențialitate sau dacă doriți să adresați o reclamație cu privire la modalitatea de prelucrare a datelor cu caracter personal, vă rugăm să vă adresați Responsabilului cu Protecția Datelor cu Caracter personal la următoarele date de contract:</p>

                <ul>
                    <li>adresă de e-mail: [sesizari@anvelope.ro];</li>
                    <li>adresă poștală: [Str. Viitorului, nr. 96, sector 2, Bucureşti];</li>
                </ul>
            </li>
        </ol>

            <br>
        </el-card>    

    </div>
</template>

<script>
import moment from 'moment';
// import Finalizare from './Finalizare.vue';
// import settings from "../backend/LocalSettings";

    export default {
        
        name: "Amanare",
        components: {
        },
        data() {
            return {
                
            }
        },
        methods: {

        },
        

        mounted() {
          
           
        }
    }
</script>

<style lang="less">
 

    ul {
        text-indent: 0%;
    }


    .rezervari {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }




</style>
